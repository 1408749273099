// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/navigation/SideMenuItem.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/navigation/SideMenuItem.tsx");
  import.meta.hot.lastModified = "1724328544936.7676";
}
// REMIX HMR END

import { usePathName } from "~/hooks/usePathName";
import { cn } from "~/utils/cn";
import { LinkButton } from "../primitives/Buttons";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../primitives/Tooltip";
import { Icon } from "../primitives/Icon";
import { IconExclamationCircle } from "@tabler/icons-react";
export function SideMenuItem({
  icon,
  iconColor,
  name,
  to,
  hasWarning,
  badge,
  target,
  subItem = false
}) {
  _s();
  const pathName = usePathName();
  const isActive = pathName === to;
  return <LinkButton variant={subItem ? "small-menu-sub-item" : "small-menu-item"} fullWidth textAlignLeft LeadingIcon={icon} leadingIconClassName={isActive ? iconColor : "text-text-dimmed"} to={to} target={target} className={cn("text-text-bright group-hover:bg-charcoal-750", subItem ? "text-text-dimmed" : "", isActive ? "bg-tertiary text-text-bright" : "group-hover:text-text-bright")}>
      <div className="flex w-full items-center justify-between">
        {name}
        <div className="flex items-center gap-1">
          {badge !== undefined && <MenuCount count={badge} />}
          {typeof hasWarning === "string" ? <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Icon icon={IconExclamationCircle} className="h-5 w-5 text-error" />
                </TooltipTrigger>
                <TooltipContent className="flex items-center gap-1 border border-error bg-error/20 backdrop-blur-xl">
                  {hasWarning}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider> : hasWarning && <Icon icon={IconExclamationCircle} className="h-5 w-5 text-error" />}
        </div>
      </div>
    </LinkButton>;
}
_s(SideMenuItem, "NMaPwz5Mfru/Lvs73c7HWspDX+A=", false, function () {
  return [usePathName];
});
_c = SideMenuItem;
function MenuCount({
  count
}) {
  return <div className="rounded-full bg-charcoal-900 px-2 py-1 text-xxs uppercase tracking-wider text-text-dimmed">
      {count}
    </div>;
}
_c2 = MenuCount;
var _c, _c2;
$RefreshReg$(_c, "SideMenuItem");
$RefreshReg$(_c2, "MenuCount");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;