// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/navigation/SideMenuHeader.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/navigation/SideMenuHeader.tsx");
  import.meta.hot.lastModified = "1709804301419.2952";
}
// REMIX HMR END

import { useNavigation } from "@remix-run/react";
import { useEffect, useState } from "react";
import { Paragraph } from "../primitives/Paragraph";
import { Popover, PopoverContent, PopoverCustomTrigger } from "../primitives/Popover";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
export function SideMenuHeader({
  title,
  children
}) {
  _s();
  const [isHeaderMenuOpen, setHeaderMenuOpen] = useState(false);
  const navigation = useNavigation();
  useEffect(() => {
    setHeaderMenuOpen(false);
  }, [navigation.location?.pathname]);
  return <div className="group flex items-center justify-between pl-1.5">
      <Paragraph variant="extra-extra-small/caps" className="cursor-default truncate text-charcoal-500">
        {title}
      </Paragraph>
      {children !== undefined ? <Popover onOpenChange={open => setHeaderMenuOpen(open)} open={isHeaderMenuOpen}>
          <PopoverCustomTrigger className="p-1">
            <EllipsisHorizontalIcon className="h-4 w-4 text-charcoal-500 transition group-hover:text-text-bright" />
          </PopoverCustomTrigger>
          <PopoverContent className="min-w-max overflow-y-auto p-0 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-charcoal-600" align="start">
            <div className="flex flex-col gap-1 p-1">{children}</div>
          </PopoverContent>
        </Popover> : null}
    </div>;
}
_s(SideMenuHeader, "PIpvp2iL778+b8xB3Zi4yzLEnAM=", false, function () {
  return [useNavigation];
});
_c = SideMenuHeader;
var _c;
$RefreshReg$(_c, "SideMenuHeader");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;