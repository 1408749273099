// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/layout/AppLayout.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/layout/AppLayout.tsx");
  import.meta.hot.lastModified = "1720450759789.259";
}
// REMIX HMR END

import { useOptionalOrganization } from "~/hooks/useOrganizations";
import { cn } from "~/utils/cn";
import { useShowUpgradePrompt } from "../billing/v3/UpgradePrompt";

/** This container is used to surround the entire app, it correctly places the nav bar */
export function AppContainer({
  children
}) {
  return <div className={cn("grid h-full w-full grid-rows-1 overflow-hidden")}>{children}</div>;
}
_c = AppContainer;
export function MainBody({
  children
}) {
  return <div className={cn("grid grid-rows-1 overflow-hidden")}>{children}</div>;
}

/** This container should be placed around the content on a page */
_c2 = MainBody;
export function PageContainer({
  children
}) {
  _s();
  const organization = useOptionalOrganization();
  const showUpgradePrompt = useShowUpgradePrompt(organization);
  return <div className={cn("grid overflow-hidden", showUpgradePrompt.shouldShow ? "grid-rows-[5rem_1fr]" : "grid-rows-[2.5rem_1fr]")}>
      {children}
    </div>;
}
_s(PageContainer, "zssdcHQPVsn+rxmwJEAPyh4XYSs=", false, function () {
  return [useOptionalOrganization, useShowUpgradePrompt];
});
_c3 = PageContainer;
export function PageBody({
  children,
  scrollable = true,
  className
}) {
  return <div className={cn(scrollable ? "overflow-y-auto p-3 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-charcoal-600" : "overflow-hidden", className)}>
      {children}
    </div>;
}
_c4 = PageBody;
export function MainCenteredContainer({
  children,
  className
}) {
  return <div className="h-full w-full overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-charcoal-600">
      <div className={cn("mx-auto mt-6 max-w-xs overflow-y-auto p-1 md:mt-[22vh]", className)}>
        {children}
      </div>
    </div>;
}
_c5 = MainCenteredContainer;
var _c, _c2, _c3, _c4, _c5;
$RefreshReg$(_c, "AppContainer");
$RefreshReg$(_c2, "MainBody");
$RefreshReg$(_c3, "PageContainer");
$RefreshReg$(_c4, "PageBody");
$RefreshReg$(_c5, "MainCenteredContainer");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;